import { useEffect } from "react";
import io from "socket.io-client";

const useSocketIO = (userId: string, onMessage: (data: any) => void) => {
  const socketioServer = process.env.REACT_APP_SOCKETIO_CONNECTION_STAGING;
  useEffect(() => {
    const socket = io(socketioServer, {
      query: { userId },
    });

    socket.on("kycStatusUpdate", (data) => {
        console.log("Inside Socket io web hook")
      console.log("Received kycStatusUpdate: ", data);
      onMessage(data);
    });

    socket.on("documentAlreadyUsed", (data) => {
      console.log("Received documentAlreadyUsed: ", data);
      onMessage(data);
    });

    return () => {
      socket.disconnect();
    };
  }, [userId, onMessage]);
};

export default useSocketIO;
