import { IcMetamask, IcTickmark } from '@/assets';
import IcCross from '@/assets/ic_cross';
import Loader from '@/components/Loader';
import Button from '@/components/UI/Button';
import useAuth from '@/hooks/useAuth';
import { joinPenomoPreSale } from '@/services/api';
import { IUser } from '@/types';
import { Modal } from 'flowbite-react';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { z } from 'zod';
const walletAddressSchema = z.string().regex(/^0x[a-fA-F0-9]{40}$/, "Invalid Wallet Address");

interface JoinPenomoPreSaleModalProps {
    handleClose: (isRefresh: boolean) => void;
    user: IUser;
}
enum RenderStatus {
    NOT_STARTED = "NOT_STARTED",
    SUCCESS = "SUCCESS",
}

const JoinPenomoPreSaleModal = ({ handleClose, user }: JoinPenomoPreSaleModalProps) => {

    const { userId } = useAuth();

    const [renderStatus, setRenderStatus] = useState<RenderStatus>(RenderStatus.NOT_STARTED);
    const [walletAddress, setWalletAddress] = useState("");
    const [walletAddressError, setWalletAddressError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const handleWalletAddressChange = (event: any) => {
        const address = event.target.value;
        setWalletAddress(address);
        
        // Validate wallet address format immediately on input
        const validation = walletAddressSchema.safeParse(address);
        setWalletAddressError(validation.success ? "" : validation.error.issues[0].message);
    };

    const handleSubmit = async () => {
        try {
            const validation = walletAddressSchema.safeParse(walletAddress);
            if (!validation.success) {
                setWalletAddressError(validation.error.issues[0].message);
                return;
            }

            setIsSubmitting(true);
            const response = await joinPenomoPreSale(userId, {
                penomoWalletAddress: walletAddress
            })
            setIsSubmitting(false);
            if(response.code == 201 && response.data) {
                setRenderStatus(RenderStatus.SUCCESS);
            } else {
                toast.error(response.message ?? 'Failed to join presale, please try again')
            }
            
        } catch (error: any) {
            toast.error('Failed to join presale, please try again')
            setIsSubmitting(false);
        }
    };

    return (
        <Modal show={true} onClose={()=>{handleClose(false)}} position={'center'} size={'2xl'}>
            <Modal.Body className="p-4 bg-monochrome-100">
                <div className={"flex justify-between items-center pb-5"}>
                    <h2 className="mb-0">Getting Whitelisted</h2>
                    <Button classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl" rounded
                        onClick={()=>{handleClose(false)}}>
                        <IcCross />
                    </Button>
                </div>
                <hr className="border-monochrome-40" />
                {
                    renderStatus === RenderStatus.NOT_STARTED && (
                        <section className="p-4 flex flex-col gap-7 mt-4 items-center">
                            <IcMetamask />
                            <div className='flex flex-col text-center gap-2'>
                                <h3>Submit your Metamask wallet address</h3>
                                <p className='text-sm text-monochrome-20'>Enter your Metamask wallet address to join the PNMO community presale whitelist</p>
                            </div>
                            <div className='w-full'>
                                <input type='text' className='w-full p-3' onChange={handleWalletAddressChange} placeholder='Enter your MetaMask wallet address' />
                                {
                                    walletAddressError != '' && <p className='text-red-500 mt-2'>{walletAddressError}</p>
                                }
                            </div>
                            <div className='flex flex-col w-full gap-4'>
                                <Button secondary rounded classNames="w-full text-black hover:text-white" onClick={handleSubmit} disabled={isSubmitting || walletAddress == '' || walletAddressError != ''}>
                                    {isSubmitting ? <Loader isLoading={true} /> : "Join PNMO Community Presale"}
                                </Button>
                            </div>
                        </section>
                    )
                }
                {
                    renderStatus === RenderStatus.SUCCESS && (
                        <section className="p-4 flex flex-col gap-7 mt-4 items-center">
                            <IcTickmark className="h-[50px] p-3 aspect-square text-green-500 bg-green-10 rounded-full" />
                            <div className='flex flex-col text-center gap-2'>
                                <h3>Whitelisting successful</h3>
                                <p className='text-sm text-monochrome-20'>You are now eligible to participate in the PNMO presale</p>
                            </div>
                            <div className='flex flex-col w-full gap-4 mt-8'>
                                <Button primary rounded classNames="w-full" onClick={()=>{handleClose(true)}} >
                                    Close
                                </Button>
                            </div>
                        </section>
                    )
                }
            </Modal.Body>
        </Modal>
    )
}

export default JoinPenomoPreSaleModal;