import { http } from "@services/http";
import { ENDPOINT } from "@constants";

export const fetchUser = (id: string) => http.get(`${ENDPOINT.USER}/${id}`).then((res) => res.data);
export const fetchInvestor = (id: string) => http.get(`${ENDPOINT.INVESTOR}/${id}`).then((res) => res.data);
export const updateInvestorBankDetails = (id: string, data: any) => http.patch(`${ENDPOINT.INVESTOR}/${id}`, data).then((res) => res.data);
export const fetchTransactions = (id: string,nt:string) => http.get(`${ENDPOINT.TRANSACTIONS}/${id}/${nt}`).then((res) => res.data);
export const fetchTransactionsUsingWalletId = (id: string) => http.get(`/api/transactions/address/${id}`).then((res) => res.data);
export const fetchTransactionsUsingUserId = (id: string) => http.get(`/api/transactions/user/${id}`).then((res) => res.data);
export const fetchDocs = (id: string) => http.get(`${ENDPOINT.DOCS}/${id}`).then((res) => res.data);
export const fetchProjectsInWhichInvestorHasInvested = (id: string) => http.get(`${ENDPOINT.INVESTOR}/getTokenHoldings/${id}`).then((res) => res.data);
export const fetchAllProjects = () => http.get(ENDPOINT.PROJECTS).then((res) => res.data);
export const fetchAllNotifications = (id: string) => http.get(`${ENDPOINT.NOTIFICATION}/${id}`).then((res) => res.data);
export const readNotification = (id: string) => http.patch(`${ENDPOINT.READ_NOTIFICATION}/${id}`).then((res) => res.data);
export const readAllNotifications = (id: string) => http.patch(`${ENDPOINT.READ_ALL_NOTIFICATION}/${id}`).then((res) => res.data);
export const fetchProjectById = (id: string) => http.get(`${ENDPOINT.PROJECTSBYID}/${id}`).then((res) => res.data);
export const withdrowPeawFlipCard = (investorId: string, data: any) =>
  http.post(`${ENDPOINT.INVESTOR}/flipCards/peaqCampaign/${investorId}`, data).then((res) => res.data);
export const joinPenomoPreSale = (userId: string, data: any) => http.post(`${ENDPOINT.USER}/whitelist/wallet/${userId}`, data).then((res) => res.data);
export const updateUser = (id: string, data: any) => http.patch(`${ENDPOINT.USER}/${id}`, data).then((res) => res.data);
export const deleteUser = (id: string) => http.delete(`${ENDPOINT.USER}/${id}`).then((res) => res.data);
export const getChallenges = () => http.get(`${ENDPOINT.CHALLENGES}`).then((res) => res.data);
export const getTokenHoldings = (id: string,network:string) => http.get(`${ENDPOINT.GET_TOKEN_HOLDINGS}/${id}/${network}`).then((res) => res.data);

// TWITTER
export const getTwitterAuthURL = () => http.get(`${ENDPOINT.TWITTER}auth`).then((res) => res.data);
export const twitterCallback = (code: string) => http.get(`${ENDPOINT.TWITTER}callback?code=${code}`).then((res) => res.data);
export const followTwitter = (challengeId: string) => http.get(`${ENDPOINT.TWITTER}follow/${challengeId}`).then((res) => res.data);
export const retweetTwitter = (challengeId: string) => http.get(`${ENDPOINT.TWITTER}repost/${challengeId}`).then((res) => res.data);

// DISCORD
export const getDiscordAuthURL = () => http.get(`${ENDPOINT.DISCORD}auth`).then((res) => res.data);
export const discordCallback = (code: string) => http.get(`${ENDPOINT.DISCORD}callback?code=${code}`).then((res) => res.data);
export const joinDiscord = (challengeId: string) => http.get(`${ENDPOINT.DISCORD}join/${challengeId}`).then((res) => res.data);
// export const revealFlipCard = (flipCardId: string) => http.patch(`${ENDPOINT.FLIPCARDS}/reveal/flipCard/${flipCardId}`).then((res) => res.data);

// TELEGRAM
export const getTelegramAuthURL = () => http.get(`${ENDPOINT.TELEGRAM}auth`).then((res) => res.data);
export const telegramCallback = (code: string) => http.get(`${ENDPOINT.TELEGRAM}callback?code=${code}`).then((res) => res.data);
export const checkTelegramUserJoined = (challengeId: string) => http.get(`${ENDPOINT.TELEGRAM}check/${challengeId}`).then((res) => res.data);

export const claimFlipCard = (flipCardId: string) => http.patch(`${ENDPOINT.FLIPCARDS}/reveal/claim/${flipCardId}`).then((res) => res.data);
export const getQuestStatistics = () => http.get(`${ENDPOINT.CHALLENGES}/quest/usertracking`).then((res) => res.data);
export const getLeaderboardMetrices = () => http.get(`${ENDPOINT.CHALLENGES}/leaderboard/user-metrices`).then((res) => res.data);
export const getLeaderboardRankings = () => http.get(`${ENDPOINT.CHALLENGES}/leaderboard/users/rank`).then((res) => res.data);
// export const claimAllGoldenCards = (challengeId: string) => http.post(`${ENDPOINT.CHALLENGES}/allChallengesCompleted/${challengeId}`).then((res) => res.data);
export const revealFlipCard = (flipCardId: string) => http.patch(`${ENDPOINT.FLIPCARDS}/reveal/flipCard/${flipCardId}`).then((res) => res.data);

export const tokenTransferPurchaseRequest = ( investorId: string, tokenId: string,data: any) =>http.post(`${ENDPOINT.TOKEN_TRANSFERS}/purchaseRequest/${investorId}/${tokenId}`,data).then((res) => res.data);
export const getTokenPurchaseRequests = (id: string) => http.get(`${ENDPOINT.TOKEN_TRANSFERS}/getInvestor/purchaseRequests/${id}`).then((res) => res.data);

export const repayRemainingAmount = ( pid: string) =>http.post(`${ENDPOINT.TOKEN_TRANSFERS}/repayRemainAmount/${pid}`).then((res) => res.data);
export const requestRefund =  ( pid: string) =>http.post(`${ENDPOINT.TOKEN_TRANSFERS}/requestRefundAmount/${pid}`).then((res) => res.data);


export const completeTransfer = (id: string, data: any) => http.post(`${ENDPOINT.TOKEN_TRANSFERS}/${id}/transaction-details`, data).then((res) => res.data);

export const sendTransactionHash = (data:any) => http.post(`${ENDPOINT.SEND_TRANSACTION}`, data).then((res) => res.data);
