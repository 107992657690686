import BackArrow from "@/assets/ic_previous_page";
import HoldingsTokenCard from "@/components/Page/PurchaseToken/HoldingsTokenCard";
import PendingTokenCard from "@/components/Page/PurchaseToken/PendingTokenCard";
import { useNetwork } from "@/provider/NetworkContext";
import { getTokenHoldings, getTokenPurchaseRequests } from "@/services/api";
import useStore from "@/store/useStore";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const TokenSale = () => {
  const navigate = useNavigate();
  const { investorData } = useStore();
  const investorId = investorData._id;
  const {selectedNetwork} = useNetwork();

  const { data: tokenHoldingsData } = useQuery({
    queryKey: ["getTokenHoldings", investorId],
    queryFn: () => getTokenHoldings(investorId,selectedNetwork),
    enabled: !!investorId,
    select: (res) => res.data,
  });
  console.log("Token Holdings Data  in page: ",tokenHoldingsData)

  const { data: tokenPurchaseData, isLoading, refetch } = useQuery({
    queryKey: ["getTokenPurchaseData", investorId],
    queryFn: () => getTokenPurchaseRequests(investorId),
    enabled: !!investorId,
    select: (res) => res.data,
  });
  // console.log("Token Purchase Data: ",tokenPurchaseData)

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="px-4">
      <div className="flex items-center gap-2 mb-4">
        <div
          className="bg-monochrome-100 p-2 rounded-md w-max cursor-pointer"
          onClick={() => navigate("/dashboard")}
        >
          <BackArrow />
        </div>
        <h3>Portfolio</h3>
      </div>
      <section className="flex flex-col justify-center gap-5">
      <div className="my-4 space-y-2">
        <h4 className="mb-10">Pending Token Details</h4>
        {tokenPurchaseData?.map((purchase:any) => (
          <PendingTokenCard
            key={purchase._id}
            companyWalletId={purchase.companyWalletId}
            purchaseId={purchase._id}
            tokenAmount={purchase.tokenAmount}
            isFundsReceived={purchase.isFundsReceived}
            isPurchaseRequestAccepted={purchase.isPurchaseRequestAccepted}
            tokenId={purchase.tokenId}
            projectName={purchase.projectId.symbol}
            projectData={purchase.projectId}
            refetch={refetch}
            isWaitingForConfirmation={purchase.isWaitingForConfirmation}
            isRequestedRemainingAmount={purchase?.transactionDetails?.isRequestedRemainingAmount}
            isRequestedForRefund={purchase.isRequestedForRefund}
            transactionDetails={purchase.transactionDetails}
            // You can pass other relevant data fields as props here
          />
        ))}
      </div>

      <div className="my-4 space-y-2">
        <h4 className="mb-10">Token Holdings</h4>
        {tokenHoldingsData?.map((purchase:any) => (
          <HoldingsTokenCard
            key={purchase._id}
            symbol={purchase.tokenSymbol}
            balance={purchase.tokenBalance}
            // companyWalletId={purchase.companyWalletId}
            // purchaseId={purchase._id}
            // tokenAmount={purchase.tokenAmount}
            // isFundsReceived={purchase.isFundsReceived}
            // isPurchaseRequestAccepted={purchase.isPurchaseRequestAccepted}
            // tokenId={purchase.tokenId}
            // projectName={purchase.projectId.symbol}
            // projectData={purchase.projectId}
            // refetch={refetch}
            // isWaitingForConfirmation={purchase.isWaitingForConfirmation}
            // isRequestedRemainingAmount={purchase?.transactionDetails?.isRequestedRemainingAmount}
            // isRequestedForRefund={purchase.isRequestedForRefund}
            // transactionDetails={purchase.transactionDetails}
            // You can pass other relevant data fields as props here
          />
        ))}
      </div>


      </section>
      
    </div>
  );
};

export default TokenSale;
