const IcGlobe = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.33338 2C7.0478 2 5.79109 2.38122 4.72217 3.09545C3.65325 3.80968 2.82013 4.82484 2.32816 6.01256C1.83619 7.20028 1.70747 8.50721 1.95827 9.76809C2.20908 11.029 2.82814 12.1872 3.73718 13.0962C4.64622 14.0052 5.80441 14.6243 7.06529 14.8751C8.32617 15.1259 9.6331 14.9972 10.8208 14.5052C12.0085 14.0132 13.0237 13.1801 13.7379 12.1112C14.4522 11.0423 14.8334 9.78558 14.8334 8.5C14.8314 6.7767 14.1459 5.12455 12.9274 3.906C11.7088 2.68744 10.0567 2.00198 8.33338 2ZM13.8334 8.5C13.8338 9.00723 13.7638 9.51205 13.6253 10H11.2184C11.3718 9.00588 11.3718 7.99412 11.2184 7H13.6253C13.7638 7.48795 13.8338 7.99277 13.8334 8.5ZM6.70838 11H9.95838C9.63822 12.0491 9.08238 13.0111 8.33338 13.8125C7.58473 13.0109 7.02894 12.049 6.70838 11ZM6.46463 10C6.29292 9.00737 6.29292 7.99263 6.46463 7H10.2071C10.3788 7.99263 10.3788 9.00737 10.2071 10H6.46463ZM2.83338 8.5C2.83293 7.99277 2.90298 7.48795 3.0415 7H5.44838C5.295 7.99412 5.295 9.00588 5.44838 10H3.0415C2.90298 9.51205 2.83293 9.00723 2.83338 8.5ZM9.95838 6H6.70838C7.02854 4.95087 7.58437 3.98885 8.33338 3.1875C9.08203 3.98911 9.63781 4.95105 9.95838 6ZM13.229 6H11.0028C10.7221 4.97058 10.2492 4.00356 9.609 3.15C10.3826 3.33583 11.1068 3.68674 11.7321 4.17866C12.3573 4.67058 12.8688 5.29189 13.2315 6H13.229ZM7.05775 3.15C6.41753 4.00356 5.94462 4.97058 5.664 6H3.43525C3.79792 5.29189 4.30942 4.67058 4.93468 4.17866C5.55994 3.68674 6.28418 3.33583 7.05775 3.15ZM3.43525 11H5.664C5.94462 12.0294 6.41753 12.9964 7.05775 13.85C6.28418 13.6642 5.55994 13.3133 4.93468 12.8213C4.30942 12.3294 3.79792 11.7081 3.43525 11ZM9.609 13.85C10.2492 12.9964 10.7221 12.0294 11.0028 11H13.2315C12.8688 11.7081 12.3573 12.3294 11.7321 12.8213C11.1068 13.3133 10.3826 13.6642 9.609 13.85Z"
        fill="#9E9E9E"
      />
    </svg>
  );
};

export default IcGlobe;
