import KycVerificationBanner from "@/components/KycVerificationBanner";
import PeaqRewardingCampaign from "@/components/Page/Dashboard/PeaqRewardBanner";
import RawTokenList from "@/components/Page/RAWToken/RawTokenList";
import RecentTransactions from "@/components/Page/RecentTransactions/RecentTransactions";
import PenomoPreSale from "@/components/PenomoPreSaleBanner";
import useAuth from "@/hooks/useAuth";
import { ITransaciton } from "@/types/transactions";
import BalanceCard from "@components/BalanceCard";
import Loader from "@components/Loader";
import PenomoFlipCards from "@components/PenomoRewards";
import { fetchInvestor, fetchUser, getTokenPurchaseRequests } from "@services/api";
import useStore from "@store/useStore";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useSocketIO from "@/hooks/useSocketIO";
import toast from "react-hot-toast";
import PurchaseComponent from "@/components/Page/Dashboard/PurchaseComponent";
import PaymentTokenModal from "@/components/Page/Dashboard/PaymentToken";

interface ITransactionsProps {
  selectedTransaction: ITransaciton | null;
  setSelectedTransaction: Dispatch<SetStateAction<ITransaciton | null>>;
}

// List of restricted countries by their short codes
const restrictedCountries = ["IR", "KP", "SY", "US", "UM", "IRN", "PRK", "SYR", "USA"];

const DashboardPage = (props: ITransactionsProps) => {
  const { userId, investorId } = useAuth();
  const { setUser, setInvestor, userData: storeUserData } = useStore();
  const queryClient = useQueryClient();
  const [kycStatus, setKycStatus] = useState<string>("Not Submitted");
  const [show, setShow] = useState(false); // temporary state for purchase modal

  // temp func
  const handlenModal = () => {
    setShow((prev) => !prev);
  };

  useEffect(() => {
    queryClient?.invalidateQueries({ queryKey: ["getUser", "getInvestor", "getTransactions"] });
  }, [userId, investorId, queryClient]);

  useSocketIO(userId, (data) => {
    if (data.kycStatus) {
      setKycStatus(data.kycStatus); // Update the kycStatus state dynamically
      toast.success(`KYC status updated to ${data.kycStatus}`);
    } else {
      toast.error(data.message);
    }
  });

  const { data: userData = null, isLoading: isLoadingUser = false } =
    useQuery({
      queryKey: ["getUser", userId],
      queryFn: () => fetchUser(userId),
      enabled: !!userId,
    }) || {};

  const {
    data: tokenPurchaseData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["getTokenPurchaseData", investorId],
    queryFn: () => getTokenPurchaseRequests(investorId),
    enabled: !!investorId,
    select: (res) => res.data,
  }) || {};

  const { data: investorData = null } =
    useQuery({
      queryKey: ["getInvestor", investorId],
      queryFn: () => fetchInvestor(investorId),
      enabled: !!investorId,
    }) || {};

  useEffect(() => {
    if (userData) {
      setUser(userData.data);
      setKycStatus(userData.data.kycStatus); // Set initial KYC status
    }
    if (investorData && investorData.data) {
      setInvestor(investorData.data);
    }
  }, [userData, investorData, setUser, setInvestor]);

  const getNotifications = () => {
    const notifications = [];

    if (userData?.statusUpdates) {
      const userNotifications = userData.statusUpdates.map((notification: any) => ({
        ...notification,
        type: "user",
      }));
      notifications.push(...userNotifications);
    }

    if (investorData?.statusUpdates) {
      const investorNotifications = investorData.statusUpdates.map((notification: any) => ({
        ...notification,
        type: "investor",
      }));
      notifications.push(...investorNotifications);
    }

    return notifications;
  };

  const notifications = getNotifications();
  const isVerified = kycStatus === "Verified";

  const FLIPCARD_FROM_ADDRESS = "0xa7d36B87D814257ADCa79cFe521f2a3fdcDdfDDa".toLowerCase();
  const PENOMO_TOKEN_SYMBOL = "PNMO";

  const getTransactionType = (transaction: any) => {
    const { from, tokenSymbol } = transaction;
    if (tokenSymbol === PENOMO_TOKEN_SYMBOL && from.toLowerCase() === FLIPCARD_FROM_ADDRESS) {
      return "flipCard transaction";
    }
    return transaction.transactionType;
  };

  const getCardClass = (transaction: any) => {
    const transactionType = getTransactionType(transaction);
    return transactionType === "flipCard transaction" ? "cards flipCard-transaction" : "cards";
  };

  // Determine if components should be displayed based on investorType
  const shouldDisplayComponents = () => {
    const investorType = investorData?.data?.investorType;
    return investorType == "Retail";
  };

  // Determine if the user's country of residence is restricted
  const isCountryRestricted = () => {
    const countryOfResidence = userData?.data?.countryOfResidence;
    return restrictedCountries.includes(countryOfResidence);
  };

  return (
    <div className="flex gap-4 flex-col">
      {isLoadingUser ? (
        <div className="min-h-[40px] flex align-center justify-center">
          <Loader isLoading={true} />
        </div>
      ) : (
        !isCountryRestricted()  && <KycVerificationBanner isVerified={isVerified} />
      )}
      {userData && (
        <>
          {/* {tokenPurchaseData && tokenPurchaseData.length > 0 && <PurchaseComponent handleModal={handlenModal} />} */}
          {/* {show && <PaymentTokenModal show={show} handleModal={handlenModal} />} */}
          {shouldDisplayComponents() && (
            // <section className="grid grid-cols-1 xl:grid-cols-2 gap-4">
            //   <>
            //     <PenomoPreSale isVerified={isVerified} userData={userData.data} />
            //     <PeaqRewardingCampaign isVerified={isVerified} userData={userData.data} />
            //   </>
            // </section>
            <section className="w-full">
              <PenomoPreSale isVerified={isVerified} userData={userData.data} />
            </section>
          )}
        </>
      )}
      <section className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <BalanceCard />
        <PenomoFlipCards />
      </section>
      <section className="grid grid-cols-1 xl:grid-cols-2 gap-4 ">
        <RawTokenList />
        <RecentTransactions investorId={investorId} {...props} />
      </section>
    </div>
  );
};

export default DashboardPage;
