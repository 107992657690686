import { jwtDecode } from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";

import { AuthContext } from "@/provider/AuthContext";
import { ENDPOINT, ROUTES } from "@constants";
import { useWeb3Auth } from "@services/Web3AuthService"; // Updated import
import { fetchUser } from "@services/api";
import { http } from "@services/http";
import useStore from "@store/useStore";
import { WALLET_ADAPTERS } from "@web3auth/base";
import Cookies from "js-cookie";
import { z } from "zod";
import ExistingEmailModal from "./ExistingEmailModal";
import Button from "./UI/Button";

interface Web3AuthResponse {
  authToken: string;
  investorId: string;
  userId: string;
}

const emailSchema = z.string().email("Please enter a valid email address");

const Login = () => {
  const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [showExistingEmailModal, setShowExistingEmailModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setUser } = useStore();
  const { web3auth, initializeWeb3Auth } = useWeb3Auth(); // Use the hook

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const web3AuthToken = localStorage.getItem("web3AuthToken");
    if (authToken && web3AuthToken) {
      setIsLoggedIn(true);
    }

    // Retrieve email from cookies if it exists
    const storedEmail = Cookies.get("email");
    console.log("Email Stored in Cookie: ", storedEmail);
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleWeb3AuthLogin = async (email: string) => {
    if (isInitializing) return;
    setIsInitializing(true);

    try {
      const validationResult = emailSchema.safeParse(email);

      if (!validationResult.success) {
        setEmailError(validationResult.error.issues[0].message);
        setIsInitializing(false);
        return;
      }
      setEmailError(null);

      if (!initialized) {
        await initializeWeb3Auth(); // Use the initializeWeb3Auth function
        setInitialized(true);
      }

      await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
        loginProvider: "email_passwordless",
        extraLoginOptions: {
          login_hint: email,
        },
      });

      const userInfo = await web3auth.getUserInfo();
      console.log("UserInfo From Web3Auth: ", userInfo);
      const emailFromUserInfo = userInfo.email;

      if (!web3auth.provider) {
        toast.error("Web3Auth provider is not available! Try again.");
        throw new Error("Web3Auth provider is not available");
      }

      const web3 = new Web3(web3auth.provider as any);
      const accounts = await web3.eth.getAccounts();
      if (accounts.length === 0) throw new Error("No account found");
      const publicAddress = accounts[0];
      console.log("Public Address from Web3Auth: ", publicAddress);

      const idToken = (await web3auth.authenticateUser()).idToken;
      console.log("Id Token from Web3Auth: ", idToken);

      try {
        const response = await http.post<Web3AuthResponse>(
          `${ENDPOINT.INVESTOR}/web3auth`,
          { email: emailFromUserInfo, publicAddress },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        console.log("response from backend for Web3Auth: ", response);

        if (response?.status === 409) {
          setErrorMessage(response.data?.message);
          setShowExistingEmailModal(true);
        }

        if (response?.status === 200) {
          const authToken = response.data?.data?.authToken;
          if (authToken) {
            login(authToken, idToken, web3auth.provider);
            localStorage.setItem("authToken", authToken);
            localStorage.setItem("web3AuthToken", idToken);

            // Save email to cookies for auto-fill later
            Cookies.set("email", emailFromUserInfo, { expires: 365 });
            console.log("Set Email in Cookie for 1 year: ");

            const decoded: any = jwtDecode(authToken);
            const userId = decoded?.userId;
            const userData = await fetchUser(userId);
            if (userData.data) {
              setUser(userData.data);
              const isProfileExisting = userData.data.isProfileExisting;
              navigate(isProfileExisting ? ROUTES.DASHBOARD : ROUTES.PROFILE_SETUP);
            }
          }
        }
      } catch (error: any) {
        if (error.response) {
          console.log("409 Conflict error detected:", error.response.data.message);
          setErrorMessage(error.response.data.message);
        } else {
          toast.error("Login failed. Please try again.");
        }
        console.error("Web3Auth login error:", error);
      }
    } catch (error) {
      console.error("Web3Auth login error:", error);
    } finally {
      setIsInitializing(false);
    }
  };

  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleWeb3AuthLogin(email);
    }
  };

  return (
    <>
      {!isLoggedIn && (
        <div className="mt-8 flex flex-col items-center">
          <input
            type="email"
            placeholder="Enter your email address"
            value={email}
            className="!text-center !w-[250px] !border-b !border-green-500 !rounded-none placeholder-[#9E9E9E] text-[0.688rem] !important"
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(null);
            }}
            onKeyDown={handleEnterKey}
          />
          {emailError && <p className="text-red-500 text-xs mt-2">{emailError}</p>}
          <Button classNames="mt-4 text-black py-2 px-6" onClick={() => handleWeb3AuthLogin(email)} secondary rounded>
            Log in
          </Button>
        </div>
      )}
      <ExistingEmailModal
        show={showExistingEmailModal}
        message={errorMessage}
        handleClose={() => {
          Cookies.remove("email"); // Remove email from cookies on logout
          localStorage.removeItem("authToken");
          localStorage.removeItem("web3AuthToken");
          setShowExistingEmailModal(false);
          window.location.reload();
        }}
      />
    </>
  );
};

export default Login;
