import React, { useState } from "react";
import { Modal } from "flowbite-react";
import Button from "@/components/UI/Button";
import PaymentTokenModal from "../Dashboard/PaymentToken";
import successImage from "@/assets/success.png";
import infoImage from "@/assets/info.png";
import IcCross from "@/assets/ic_cross";
import { repayRemainingAmount, requestRefund } from "@/services/api";
import carbon from "@/assets/carbon_energy-renewable.png";

const HoldingsTokenCard = ({
  key,
  symbol,
  balance
}: any) => {
    console.log("Symbol: ",symbol)

    const formatWeiToEther = (weiValue) => {
      const etherValue = weiValue / Math.pow(10, 18); // Divide by 10^18 to convert wei to ether
      return etherValue; // Format to 4 decimal places (adjust as needed)
    };

  return (
    <div className="bg-monochrome-100 shadow-sm p-3 rounded-md flex items-center justify-between">
      {/* Card content (same as before) */}
      <div className="flex gap-2 items-center">
        <div className="flex justify-center items-center bg-monochrome-60 rounded-md size-[40px] overflow-hidden">
          <img src="https://picsum.photos/200/300" alt="purchase-image" className="w-full" />
        </div>
        <div className="flex flex-col">
          <p className="text-monochrome-20">Name</p>
          <p className="text-white font-bold">{symbol}</p>
        </div>
      </div>
      <div className="space-y-1">
        <p className="text-monochrome-20">Token Balance</p>
        <p className="text-white font-bold">{formatWeiToEther(balance)}</p>
      </div>
      <div className="space-y-1">
        <p className="text-monochrome-20">Actual Amount</p>
        <p className="text-white font-bold">${(formatWeiToEther(balance)) * 500}</p>
      </div>
      <div className="space-y-1">
        <p className="text-monochrome-20">APY</p>
        <p className="text-white font-bold">10%</p>
      </div>
    </div>
  );
};

export default HoldingsTokenCard;