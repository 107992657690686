import React from "react";

interface PortfolioSummaryCardProps {
  title: string;
  amount: number;
  Icon: React.FC;
}

const PortfolioSummaryCard = ({ Icon, title, amount }: PortfolioSummaryCardProps) => {
  return (
    <div className="flex bg-monochrome-100 p-[16px] rounded-[8px]">
      <div className=" bg-monochrome-60 rounded-[8px] p-[10px] w-[40px] h-[40px] flex justify-center items-center mr-[16px]">
        <Icon />
      </div>
      <div>
        <p className=" text-monochrome-20 mb-4">{title}</p>
        <p className=" text-white font-medium text-[26px] leading-[39pxz]">
          ${amount.toLocaleString()} <span className=" text-monochrome-20 font-normal text-base">USD</span>
        </p>
      </div>
    </div>
  );
};

export default React.memo(PortfolioSummaryCard);
