const IcLockFilled = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5555 1.83325C9.43956 1.83325 10.2874 2.18444 10.9125 2.80956C11.5376 3.43468 11.8888 4.28253 11.8888 5.16658V7.16658C12.4193 7.16658 12.928 7.3773 13.303 7.75237C13.6781 8.12744 13.8888 8.63615 13.8888 9.16658V13.1666C13.8888 13.697 13.6781 14.2057 13.303 14.5808C12.928 14.9559 12.4193 15.1666 11.8888 15.1666H5.22217C4.69173 15.1666 4.18303 14.9559 3.80795 14.5808C3.43288 14.2057 3.22217 13.697 3.22217 13.1666L3.22217 9.16658C3.22217 8.63615 3.43288 8.12744 3.80795 7.75237C4.18303 7.3773 4.69173 7.16658 5.22217 7.16658V5.16658C5.22217 4.28253 5.57336 3.43468 6.19848 2.80956C6.8236 2.18444 7.67145 1.83325 8.5555 1.83325ZM8.5555 9.83325C8.21912 9.83315 7.89512 9.96019 7.64847 10.1889C7.40181 10.4176 7.25073 10.7311 7.2255 11.0666L7.22217 11.1666C7.22217 11.4303 7.30037 11.6881 7.44688 11.9073C7.59338 12.1266 7.80162 12.2975 8.04526 12.3984C8.28889 12.4993 8.55698 12.5257 8.81562 12.4743C9.07426 12.4229 9.31184 12.2959 9.49831 12.1094C9.68478 11.9229 9.81177 11.6853 9.86321 11.4267C9.91466 11.1681 9.88826 10.9 9.78734 10.6563C9.68642 10.4127 9.51553 10.2045 9.29626 10.058C9.077 9.91145 8.81921 9.83325 8.5555 9.83325ZM8.5555 3.16659C8.02507 3.16659 7.51636 3.3773 7.14129 3.75237C6.76621 4.12744 6.5555 4.63615 6.5555 5.16658V7.16658H10.5555V5.16658C10.5555 4.63615 10.3448 4.12744 9.96971 3.75237C9.59464 3.3773 9.08593 3.16659 8.5555 3.16659Z"
        fill="#9E9E9E"
      />
    </svg>
  );
};

export default IcLockFilled;
