import React from "react";
import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import { IPortfolioRequest } from "@/types";

import { IcSwap, IcArrowUpSort, IcArrowDownSort, IcTransferExchange, IcInsufficientFund } from "@/assets";
import { classNames } from "@/utils";

import {
  CompletePaymentModal,
  CompletePaymentAskModal,
  PaymentCheckModal,
  ResolvePaymentModal,
  PayRemainingModal,
  RequestRefundAskModal,
  RequestRefundModal,
} from "./Modal";

type AssetType = "BATTERY" | "SOLAR" | "WIND" | "EVCHARGING";
interface IPortfolioRequestProps {
  data: IPortfolioRequest[];
  isLoading: boolean;
  refecthRequest: () => void;
}

const getAsset = (asset: string): string => {
  const enums: Record<AssetType, string> = {
    BATTERY: "/assets/dummy/battery.jpg",
    SOLAR: "/assets/dummy/solar.jpg",
    WIND: "/assets/dummy/wind.jpg",
    EVCHARGING: "/assets/dummy/ev.jpg",
  };
  // Type narrowing
  const isValidAssetType = (type: string): type is AssetType => {
    return ["BATTERY", "SOLAR", "WIND", "EVCHARGING"].includes(type);
  };
  const imageAsset = isValidAssetType(asset) ? enums[asset] : enums["BATTERY"];
  return imageAsset;
};

const renderToken = (data: IPortfolioRequest) => {
  const asset = data.projectId.assetIds[0]?.assetType as string;
  const imageAsset = getAsset(asset);

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center p-[16px]">
        <img src={data.projectId.projectImage || imageAsset} className="w-[40px] h-[40px] rounded-[8px] mr-[16px] object-cover" alt="token" />
        <div>
          <p className="text-white font-medium text-xs">{data.projectId.name}</p>
          <p className="text-monochrome-20 text-xs">{data.projectId.projectDescription}</p>
        </div>
      </div>
    </div>
  );
};

const renderStatusWording = (data: IPortfolioRequest) => {
  const { isWaitingForConfirmation, isPurchaseRequestAccepted, transactionDetails } = data;
  const { isRequestedRemainingAmount } = transactionDetails || {};

  if (isWaitingForConfirmation) {
    return "View Bank Details";
  } else if (isRequestedRemainingAmount) {
    return "Resolve Payment";
  } else if (isPurchaseRequestAccepted) {
    return "Complete Payment";
  } else {
    return "Requested";
  }
};

const renderStatus = (data: IPortfolioRequest, onClick: (data: IPortfolioRequest, status: string) => void) => {
  const { isWaitingForConfirmation, isPurchaseRequestAccepted, transactionDetails, isRequestedForRefund, isAmountRefunded } = data;
  const { isRequestedRemainingAmount } = transactionDetails || {};
  if (isAmountRefunded) {
    return (
      <div className="rounded-lg bg-primary-10 px-2 py-1">
        <span className="text-monochrome-20 text-xs font-normal leading-[18px]">Amount Refunded</span>
      </div>
    );
  } else if (isRequestedForRefund) {
    return (
      <div className="rounded-lg bg-primary-10 px-2 py-1">
        <span className="text-monochrome-20 text-xs font-normal leading-[18px]">Refund Requested</span>
      </div>
    );
  }else if (isWaitingForConfirmation) {
    return (
      <button
        className="cursor-pointer bg-monochrome-60 border-monochrome-40 border rounded-lg px-4 py-2 flex items-center gap-2 text-white text-xs font-normal leading-[18px]"
        onClick={() => onClick(data, "complete_action")}
      >
        <IcTransferExchange />
        {renderStatusWording(data)}
      </button>
    );
  } else if (isRequestedRemainingAmount) {
    return (
      <button
        className="cursor-pointer bg-monochrome-60 border-monochrome-40 border rounded-lg px-4 py-2 flex items-center gap-2 text-white text-xs font-normal leading-[18px]"
        onClick={() => onClick(data, "resolve_payment")}
      >
        <IcInsufficientFund />
        {renderStatusWording(data)}
      </button>
    );
  } else if (isPurchaseRequestAccepted) {
    return (
      <button
        className="cursor-pointer bg-monochrome-60 border-monochrome-40 border rounded-lg px-4 py-2 flex items-center gap-2 text-white text-xs font-normal leading-[18px]"
        onClick={() => onClick(data, "complete_action")}
      >
        <IcTransferExchange />
        {renderStatusWording(data)}
      </button>
    );
  }
  return (
    <div className="rounded-lg bg-monochrome-80 px-2 py-1">
      <span className="text-monochrome-20 text-xs font-normal leading-[18px]">{renderStatusWording(data)}</span>
    </div>
  );
};

const renderSortIcon = (column: any) => {
  return (
    <div className=" ml-[4px]">
      {{
        asc: <IcArrowUpSort />,
        desc: <IcArrowDownSort />,
      }[column.getIsSorted() as string] ?? <IcSwap />}
    </div>
  );
};

const PortofolioRequests = ({ data, isLoading, refecthRequest }: IPortfolioRequestProps) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = React.useMemo<ColumnDef<IPortfolioRequest>[]>(
    () => [
      {
        accessorFn: (row) => row.projectId.name,
        id: "token",
        header: ({ column }) => <div className="px-[16px] py-[10px] flex items-center">Token {renderSortIcon(column)}</div>,
        cell: (info) => renderToken(info.row.original),
        size: 240,
      },
      {
        accessorFn: (row) => row.tokenAmount * row.projectId.tokenPrice,
        id: "payment_amount",
        header: ({ column }) => <div className="px-[16px] py-[10px] flex items-center justify-end">Payment Amount {renderSortIcon(column)}</div>,
        cell: (info) => <div className="px-[16px] py-[10px] text-right">${info.getValue<number>().toLocaleString()}</div>,
      },
      {
        accessorFn: (row) => row.tokenAmount,
        id: "token_amount",
        header: ({ column }) => <div className="px-[16px] py-[10px] flex items-center justify-end">Token amount {renderSortIcon(column)}</div>,
        cell: (info) => <div className="px-[16px] py-[10px] text-right">{info.getValue<number>()} tokens</div>,
      },
      {
        accessorFn: (row) => row.projectId.irr,
        id: "apy",
        header: ({ column }) => <div className="px-[16px] py-[10px] flex items-center justify-center">APY {renderSortIcon(column)}</div>,
        cell: (info) => <div className="px-[16px] py-[10px] text-center">{10}%</div>,
      },
      {
        accessorFn: (row) => renderStatusWording(row),
        id: "status",
        header: ({ column }) => <div className="px-[16px] py-[10px] flex items-center justify-end">Status & Action {renderSortIcon(column)}</div>,
        cell: (info) => <div className="flex items-center justify-end px-[16px] py-[10px]">{renderStatus(info.row.original, openModal)}</div>,
      },
    ],
    []
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const [paymentData, setPaymentData] = React.useState<IPortfolioRequest | null>(null);
  const [isCompleteOpen, setIsCompleteOpen] = React.useState<boolean>(false);
  const [isCompleteAskOpen, setIsCompleteAskOpen] = React.useState<boolean>(false);
  const [isPaymentCheckOpen, setIsPaymentCheckOpen] = React.useState<boolean>(false);
  const [isResolveOpen, setIsResolveOpen] = React.useState<boolean>(false);
  const [isPayRemainingOpen, setIsPayRemainingOpen] = React.useState<boolean>(false);
  const [isRefundAskOpen, setIsRefundAskOpen] = React.useState<boolean>(false);
  const [isRefundOpen, setIsRefundOpen] = React.useState<boolean>(false);

  function openModal(data: IPortfolioRequest, status: string) {
    setPaymentData(data);
    if (status === "complete_action") {
      setIsCompleteOpen(true);
    } else {
      setIsResolveOpen(true);
    }
  }

  function closeModal() {
    setPaymentData(null);
    setIsCompleteOpen(false);
    setIsCompleteAskOpen(false);
    setIsPaymentCheckOpen(false);
    setIsResolveOpen(false);
    setIsPayRemainingOpen(false);
    setIsRefundAskOpen(false);
    setIsRefundOpen(false);
    refecthRequest();
  }

  function confirmPayment() {
    setIsCompleteOpen(false);
    setIsPayRemainingOpen(false);
    setIsCompleteAskOpen(true);
    refecthRequest();
  }

  function confirmPaymentAsk() {
    setIsCompleteAskOpen(false);
    setIsPaymentCheckOpen(true);
    refecthRequest();
  }

  function payResolve() {
    setIsResolveOpen(false);
    setIsPayRemainingOpen(true);
    refecthRequest();
  }

  function refundResolve() {
    setIsResolveOpen(false);
    setIsRefundAskOpen(true);
    refecthRequest();
  }

  function confirmRefundAsk() {
    setIsRefundAskOpen(false);
    setIsRefundOpen(true);
    refecthRequest();
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (data.length === 0) {
    return (
      <div className="text-center mt-4">
        <p className="flex items-center justify-center min-h-screen">No Purchasing Requests</p>
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className="w-full mb-[16px]">
        <thead className=" border-b-[0.5px] border-monochrome-40">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={classNames(header.column.columnDef.meta?.className, " font-normal text-[11px] text-monochrome-20 text-left")}
                    style={{ width: header.column.getSize() }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === "asc"
                              ? "Sort ascending"
                              : header.column.getNextSortingOrder() === "desc"
                              ? "Sort descending"
                              : "Clear sort"
                            : undefined
                        }
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table
            .getRowModel()
            .rows.slice(0, 10)
            .map((row) => {
              return (
                <tr key={row.id} className=" border-b-[0.5px] border-monochrome-40 text-xs font-normal">
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className={classNames(cell.column.columnDef.meta?.className)} style={{ width: cell.column.getSize() }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>

      {paymentData && isCompleteOpen && (
        <CompletePaymentModal data={paymentData} isOpen={isCompleteOpen} onClose={closeModal} onConfirm={confirmPayment} getAsset={getAsset} />
      )}
      {paymentData && isCompleteAskOpen && (
        <CompletePaymentAskModal data={paymentData} isOpen={isCompleteAskOpen} onClose={closeModal} onConfirm={confirmPaymentAsk} />
      )}
      {paymentData && isPaymentCheckOpen && <PaymentCheckModal data={paymentData} isOpen={isPaymentCheckOpen} onClose={closeModal} />}
      {paymentData && isResolveOpen && (
        <ResolvePaymentModal data={paymentData} isOpen={isResolveOpen} onClose={closeModal} onPay={payResolve} onRefund={refundResolve} getAsset={getAsset} />
      )}
      {paymentData && isPayRemainingOpen && (
        <PayRemainingModal data={paymentData} isOpen={isPayRemainingOpen} onClose={closeModal} onConfirm={confirmPayment} />
      )}
      {paymentData && isRefundAskOpen && (
        <RequestRefundAskModal data={paymentData} isOpen={isRefundAskOpen} onClose={closeModal} onConfirm={confirmRefundAsk} />
      )}
      {paymentData && isRefundOpen && <RequestRefundModal data={paymentData} isOpen={isRefundOpen} onClose={closeModal} />}
    </div>
  );
};

export default PortofolioRequests;
