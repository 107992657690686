import React from "react";

import { IcGlobe, IcLockFilled } from "@/assets";
import { abbreviateNumber } from "@/utils";

// Update the image imports to use the public directory path
const solar = "/assets/dummy/solar.jpg";
const wind = "/assets/dummy/wind.jpg";
const battery = "/assets/dummy/battery.jpg";
const ev = "/assets/dummy/ev.jpg";
const other = "/assets/dummy/other.png";

interface ProjectCardItemProps {
  item: {
    _id: string;
    assetIds: Array<{ assetType: string }>;
    name: string;
    projectImage: string;
    fundingReceived: number;
    contractTerm: number;
    irr: number | null;
    issuanceType: string | null;
  };
  onClick: () => void;
}

const assetImages: { [key: string]: string } = {
  SOLAR: solar,
  WIND: wind,
  BATTERY: battery,
  EVCHARGEPOINT: ev,
  OTHER: other,
};

const gradientImgOverlay: React.CSSProperties = {
  backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 82.5%)",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

const renderIssuanceType = (issuanceType: string) => {
  const [publicity, type = ""] = issuanceType.split("_");
  const isPublic = publicity.toLowerCase() === "public";
  const issuanceList: { [key in "accredited" | "retail"]: string } = {
    accredited: "Accredited",
    retail: "Retail",
  };
  const displayType = issuanceList[type.toLowerCase() as keyof typeof issuanceList] || "";

  return (
    <div>
      <div className="flex items-center text-monochrome-20">
        {isPublic ? (
          <>
            <IcGlobe />
            <span className="ml-1 text-xs font-normal leading-[18px]">Public</span>
          </>
        ) : (
          <>
            <IcLockFilled />
            <span className="ml-1 text-xs font-normal leading-[18px]">Private</span>
          </>
        )}
        {displayType && <div className="w-1 h-1 bg-monochrome-20 rounded-full mx-3" />}
        <span className="text-xs font-normal leading-[18px]">{displayType}</span>
      </div>
    </div>
  );
};

const ProjectCardItem: React.FC<ProjectCardItemProps> = ({ item, onClick }: any) => {
  // Determine the image based on the asset type
  const assetType = item.assetIds.length > 0 ? item.assetIds[0]?.assetType : "OTHER";
  const imageSrc = item.projectImage ? item.projectImage : assetImages[assetType] || other;

  return (
    <button onClick={onClick} className="cursor-pointer">
      <div className=" bg-monochrome-100 rounded-lg">
        <div className="relative">
          <img src={item.projectImage || imageSrc} className="w-full h-[180px] object-cover rounded-t-lg" alt={item.name} />
          <div style={gradientImgOverlay}></div>
          <h6 className=" font-medium text-lg text-left absolute bottom-[8px] left-[8px] pr-[8px]">{item.name}</h6>
        </div>

        <div className="p-4">
          <div className="pb-4 mb-4 border-b border-monochrome-60 flex justify-between items-center">
            <div>{renderIssuanceType(item.issuanceType || "")}</div>
            <div className="py-[2px] px-[8px] rounded-[32px] bg-monochrome-60 text-white">
              <h5 className="font-medium text-sm leading-[21px]">APY {10}%</h5>
            </div>
          </div>
          <div className="grid grid-cols-2 text-left">
            <div className="border-r border-monochrome-40">
              <p className=" text-[11px] text-monochrome-20 font-normal leading-4 mb-1">Invested</p>
              <h3 className=" text-lg font-medium leading-[27px]">${abbreviateNumber(item.fundingReceived)}</h3>
            </div>
            <div className="pl-4">
              <p className=" text-[11px] text-monochrome-20 font-normal leading-4 mb-1">Duration</p>
              <h3 className=" text-lg font-medium leading-[27px]">
                {item.contractTerm || 0} Month{item.contractTerm > 1 ? "s" : ""}
              </h3>
            </div>
          </div>
          <div className="text-left mt-4 border-t border-monochrome-60 pt-4">
            <p className=" text-[11px] text-monochrome-20 font-normal leading-4 mb-1">Allowed Investor Type</p>
            <h3 className=" text-lg font-medium leading-[27px]">
              {item.allowedInvestorType
                ?.split(",")
                ?.map((word: string) => word.trim())
                .join(", ")}
            </h3>
          </div>
        </div>
      </div>
    </button>
  );
};

export default ProjectCardItem;
