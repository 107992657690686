import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import useAuth from "@/hooks/useAuth";
import { classNames } from "@/utils";
import { ROUTES } from "@constants";
import useStore from "@store/useStore";
import Logout from "./Logout";

import logo from "../assets/penomo_logo_white.svg";
import "./NavBar.css";
import { NavBarList } from "@/constants/NavMenu";
import { IcClose, IcHamburger } from "@/assets/menu";
import ContactSupportSucessModal from "./Page/ContactSupport/SucessModal";

const NavBar = ({ handleLinkClick }: any) => {
  const { userData, investorData } = useStore();
  const { pathname } = useLocation();
  const { userId } = useAuth();
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navButtons = document.querySelectorAll(".nav-button");
  const isSetupProfilePage = pathname === ROUTES.PROFILE_SETUP;
  const navigate = useNavigate();

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const handleCloseContactModal = (isSuccess: boolean) => {
    setShowSuccessDialog(isSuccess);
    setShowContactModal(false);
    navigate(ROUTES.DASHBOARD);
  };

  const handleCloseMobileMenu = (item: any = null) => {
    if (item?.link === ROUTES.CONTACTSUPPORT) {
      setShowContactModal(true);
    } else {
      navButtons.forEach((button) => button.classList.remove("active"));

      if (window.matchMedia("screen and (max-width: 1024px)").matches) {
        setIsNavOpen(!isNavOpen);
      }
    }
  };

  // Check investor type
  const isCampaignsVisible = investorData?.investorType !== "Accredited" && investorData?.investorType !== "Institutional";
  const isPNMOPreSaleVisible = investorData?.investorType !== "Accredited" && investorData?.investorType !== "Institutional";

  // Filter NavBarList based on investor type
  const filteredNavBarList = NavBarList.filter((item) => {
    if (item.title === "Campaigns" && !isCampaignsVisible) {
      return false;
    }
    if (item.title === "PNMO Presale" && !isPNMOPreSaleVisible) {
      return false;
    }
    return true;
  });

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (userId && isSetupProfilePage) {
    return null;
  }

  return (
    <>
      <nav
        className={classNames(`lg:fixed lg:h-full w-full lg:w-72 bg-monochrome-100 ${isCampaignsVisible ? "border-r border-green-30" : ""}`, {
          hidden: !userId,
        })}
      >
        <div className={`${windowHeight < 700 ? "overflow-y-scroll" : "overflow-y-none"} h-full  flex flex-col justify-between px-4 lg:pl-8 lg:pr-0  lg:py-5`}>
          <div className="w-full grow">
            {/* LOGO */}
            <div className={"h-[88px] w-full flex justify-between items-center lg:items-start"}>
              <NavLink to={ROUTES.DASHBOARD} className="flex pr-2">
                <img src={logo} className="navbar-logo h-8" alt="penomo" />
              </NavLink>
              <button
                data-collapse-toggle="navbar-default"
                type="button"
                className="borderinline-flex items-center justify-center text-sm text-gray-500 rounded-lg lg:hidden"
                aria-controls="navbar-default"
                aria-expanded="false"
                onClick={() => handleCloseMobileMenu()}
              >
                {isNavOpen ? <IcClose /> : <IcHamburger />}
              </button>
            </div>

            {/* LOGO */}
            <div className={classNames("hidden w-full z-50", { "mobile-menu": isNavOpen }, "lg:block")} id="navbar-default">
              <ul className="pl-8 py-4 lg:pl-0 lg:py-0 bg-[#1A1C22] font-medium w-full">
                {userId && !isSetupProfilePage && (
                  <>
                    <div className="flex flex-col gap-y-8 lg:justify-center pb-8">
                      {[...filteredNavBarList].splice(0, 4).map((item, index) => (
                        <li key={"menu-" + index}>
                          <NavLink
                            as={NavLink}
                            to={item.link}
                            className={`btn-tertiary-navbar flex justify-between`}
                            onClick={(e) => {
                              handleCloseMobileMenu(item);
                              handleLinkClick(e, item.link);
                            }}
                          >
                            <div className={"flex justify-items-center"}>
                              {pathname === item.link ? item.iconWhenSelected : item.icon}
                              <span className={`pl-2 flex my-auto`}>{item.title}</span>
                            </div>
                            <p className={"w-[0.25rem] h-5  bg-[#00CC9C] hidden"}></p>
                          </NavLink>
                        </li>
                      ))}
                    </div>

                    <div className="h-[1px] w-[80%] bg-gray-800"></div>

                    <div className="flex flex-col gap-y-8 lg:justify-center py-8">
                      {[...filteredNavBarList].splice(4, 4).map((item, index) => (
                        <li key={"menu-" + (index + 4)}>
                          <NavLink
                            as={NavLink}
                            to={item.link}
                            className={`btn-tertiary-navbar flex justify-between`}
                            onClick={(e) => {
                              handleCloseMobileMenu(item);
                              handleLinkClick(e, item.link);
                            }}
                          >
                            <div className={"flex justify-items-center"}>
                              {pathname === item.link ? item.iconWhenSelected : item.icon}
                              <span className={`pl-2 flex my-auto`}>{item.title}</span>
                            </div>
                            <p className={"w-[0.25rem] h-5  bg-[#00CC9C] hidden"}></p>
                          </NavLink>
                        </li>
                      ))}
                    </div>

                    <div className="h-[1px] w-[80%] bg-gray-800"></div>

                    <div className="flex flex-col gap-y-8 lg:justify-center pt-8">
                      {[...filteredNavBarList].splice(8).map((item, index) => (
                        <li key={"menu-" + (index + 8)}>
                          <NavLink
                            as={NavLink}
                            to={item.link}
                            className={`btn-tertiary-navbar flex justify-between`}
                            onClick={(e) => {
                              handleCloseMobileMenu(item);
                              handleLinkClick(e, item.link);
                            }}
                          >
                            <div className={"flex justify-items-center"}>
                              {pathname === item.link ? item.iconWhenSelected : item.icon}
                              <span className={`pl-2 flex my-auto`}>{item.title}</span>
                            </div>
                            <p className={"w-[0.25rem] h-5  bg-[#00CC9C] hidden"}></p>
                          </NavLink>
                        </li>
                      ))}
                    </div>
                    <li className="lg:hidden">
                      <Logout />
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>

          <div className="hidden lg:block">
            <Logout />
          </div>
        </div>
      </nav>

      <ContactSupportSucessModal show={showSuccessDialog} handleClose={() => setShowSuccessDialog(false)} />
    </>
  );
};

export default NavBar;
