import React from "react";
import { Modal } from "flowbite-react";
import Button from "@/components/UI/Button";

interface ExistingEmailModalProps {
  show: boolean;
  handleClose: () => void;
  message: string | null;
  clearLocalStorage?: boolean;
}

const ExistingEmailModal = ({ show, handleClose, message, clearLocalStorage = true }: ExistingEmailModalProps) => {
  const handleCloseAndClear = () => {
    if (clearLocalStorage) {
      localStorage.clear();
    }
    handleClose();

    if (clearLocalStorage) {
      window.location.reload();
    }
  };

  return (
    <Modal show={show} onClose={handleClose} position="center" size="2xl">
      <Modal.Body className="p-6 bg-monochrome-100 text-white flex flex-col gap-3">
        <h6>{message}</h6>
      </Modal.Body>
      <Modal.Footer className="p-6 bg-monochrome-100 text-white flex justify-center">
        <Button rounded primary onClick={handleCloseAndClear}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExistingEmailModal;
