import React, { useState, useEffect } from "react";
import { abbreviateNumber, classNames } from "@/utils";

// Update the image imports to use the public directory path
const solar = "/assets/dummy/solar.jpg";
const wind = "/assets/dummy/wind.jpg";
const battery = "/assets/dummy/battery.jpg";
const ev = "/assets/dummy/ev.jpg";
const other = "/assets/dummy/other.png";

interface RwaPoolsCardItemProps {
  item: {
    _id: string;
    assetIds: Array<{ assetType: string }>;
    name: string;
    isOpen: boolean;
    fundingReceived: number;
    totalFunding: number;
    progress: number;
    contractTerm: number;
    irr: number;
    poolSize: number;
  };
  onClick: () => void;
}

const assetImages: { [key: string]: string } = {
  SOLAR: solar,
  WIND: wind,
  BATTERY: battery,
  EVCHARGEPOINT: ev,
  OTHER: other,
};

const gradientImgOverlay: React.CSSProperties = {
  backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 82.5%)",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

const renderOnlineStatus = (isOpen: boolean) => {
  return (
    <div
      className={classNames(
        "bg-black/70 px-[6px] py-[2px] rounded-[6px] border w-fit flex items-center gap-[6px] mb-3",
        isOpen ? "border-primary-100 text-primary-100" : "border-danger-100 text-danger-100"
      )}
    >
      <div className={classNames(" w-[5px] h-[5px] rounded-full", isOpen ? " bg-primary-100" : " bg-danger-100")} />
      <p className=" font-normal text-[10px] leading-[15px]">{isOpen ? "Open" : "Closed"}</p>
    </div>
  );
};

const renderProgressBar = (progress: number, isOpen: boolean) => {
  return (
    <div className="w-full h-2 bg-monochrome-80 rounded-[32px] overflow-hidden">
      <div
        className={classNames(
          "h-full rounded-[32px] transition-all duration-500 ease-out",
          isOpen ? " bg-primary-100" : " bg-gradient-to-r from-danger-10 to-danger-100"
        )}
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  );
};

function convertMonthsToDays(contractTerm: number): number {
  const years = Math.floor(contractTerm / 12); // Full years
  const remainingMonths = contractTerm % 12; // Remaining months

  // 365 days for each full year and 30 days for each remaining month
  return years * 365 + remainingMonths * 30;
}

const RwaPoolsCardItem: React.FC<RwaPoolsCardItemProps> = ({ item, onClick }) => {
  // Determine the image based on the asset type
  const assetType = item.assetIds.length > 0 ? item.assetIds[0]?.assetType : "OTHER";
  const imageSrc = assetImages[assetType] || other;
  const [currentProgress, setCurrentProgress] = useState(0);

  useEffect(() => {
    setCurrentProgress(item.progress);
  }, [item.progress]);

  return (
    <button onClick={onClick} className=" cursor-pointer">
      <div className="bg-monochrome-100 rounded-lg">
        <div className="relative">
          <img src={imageSrc} className="w-full h-[180px] object-cover rounded-t-lg" alt={item.name} />
          <div style={gradientImgOverlay}></div>
          <div className="absolute bottom-[8px] left-[8px] w-[calc(100%-16px)] flex flex-col">
            {renderOnlineStatus(item.isOpen)}
            <div className=" flex justify-between items-end w-full">
              <h6 className=" font-medium text-lg text-left">{item.name}</h6>
              <div className="py-[2px] px-[8px] rounded-[32px] bg-monochrome-80 text-white min-w-fit">
                <h5 className=" font-medium text-sm leading-[21px]">APY {10}%</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="pb-4 mb-4 border-b border-monochrome-60 flex flex-col">
            <div className="grid grid-cols-2 w-full mb-[6px]">
              <div className="text-left">
                <p className=" text-xs text-white font-medium leading-[18px]">${abbreviateNumber(item.fundingReceived)}</p>
                <p className=" text-[11px] text-monochrome-20 font-normal leading-4">Total Value Locked</p>
              </div>
              <div className="text-right">
                <p className=" text-xs text-white font-medium leading-[18px]">${abbreviateNumber(item.totalFunding)}</p>
                <p className=" text-[11px] text-monochrome-20 font-normal leading-4">Capacity</p>
              </div>
            </div>
            {renderProgressBar(currentProgress, item.isOpen)}
          </div>
          <div className="grid grid-cols-2 text-left">
            <div className="border-r border-monochrome-40">
              <p className=" text-[11px] text-monochrome-20 font-normal leading-4 mb-1">Pool Size</p>
              <h3 className=" text-lg font-medium leading-[27px]">${abbreviateNumber(item.fundingReceived)}</h3>
            </div>
            <div className="pl-4">
              <p className=" text-[11px] text-monochrome-20 font-normal leading-4 mb-1">Tenure</p>
              <h3 className=" text-lg font-medium leading-[27px]">{convertMonthsToDays(item.contractTerm || 0)} Days</h3>
            </div>
          </div>
        </div>
      </div>
    </button>
  );
};

export default RwaPoolsCardItem;
