import React from 'react';
import { classNames } from '@/utils';

interface TabsProps {
  tabs: string[];          // Array of tab labels
  activeTab: number;       // Index of the active tab
  onClick: (index: number) => void; // Function to handle tab click
  className?: string;       // Additional class name
}

const Tabs: React.FC<TabsProps> = ({ tabs, activeTab, onClick, className }) => {
  return (
    <div className={classNames(className, " border-b border-monochrome-40")}>
      <ul className="flex flex-wrap -mb-px text-xs text-center text-monochrome-20 font-normal">
        {tabs.map((tab, index) => (
          <li key={index}>
            <a
              href="#"
              className={`inline-flex items-center justify-center p-4 border-b border-monochrome-40 rounded-t-lg ${
                index === activeTab
                  ? 'text-white border-primary-100 font-medium'
                  : 'border-transparent hover:text-white hover:border-primary-100 group'
              }`}
              onClick={(e) => {
                e.preventDefault();
                onClick(index);
              }}
            >
              {tab}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tabs;
