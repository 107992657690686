import React from "react";
import Button from "@/components/UI/Button";
import carbon_energy from "@/assets/carbon_energy-renewable.png";

interface InvestPageOneProps {
  data: {
    name: string;
    symbol: string;
    tokenPrice: number;
    irr: any;
    tokenAmount: number;
  };
  purchase: number;
  amount: number;
  profit: number;
  handlePurchaseChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClose: () => void;
  handleNextPage: () => void;
  formatCurrency: (value: number) => string;
}

const InvestPageOne: React.FC<InvestPageOneProps> = ({ data, purchase, amount, profit, handlePurchaseChange, handleClose, handleNextPage, formatCurrency }) => {
  const { name, symbol, tokenPrice, irr } = data;
  console.log("IRR: ", irr);

  return (
    <div>
      <h5 className="my-1 font-bold">Token</h5>
      <div className="w-full flex justify-between rounded-md border border-monochrome-40 p-2">
        <div className="flex gap-2 items-center">
          <div className="flex justify-center items-center bg-monochrome-60 p-2 rounded-full">
            <img style={{ width: "22px", height: "22px" }} src={carbon_energy} alt="" />
          </div>
          <div className="flex flex-col">
            <p className="font-bold">{name}</p>
            <p>{symbol}</p>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <div className="py-1 px-2 h-max w-max flex justify-center items-center rounded-xl bg-monochrome-60">
            <p>{formatCurrency(tokenPrice)}/token</p>
          </div>
          <div className="py-1 px-2 h-max w-max flex justify-center items-center rounded-xl bg-[#00CC9C20]">
            <p>APY: {irr === undefined ? 10 : formatCurrency(irr)}%</p>
          </div>
        </div>
      </div>
      <h5 className="mb-2 mt-3 font-bold">Token Amount</h5>
      <div className="my-3 w-full flex justify-between gap-5 items-center rounded-md border border-monochrome-40 p-2 overflow-hidden">
        <input
          type="text"
          className="w-max !text-2xl !font-bold !outline-none !hover:outline-none !bg-inherit !border-none"
          value={purchase}
          onChange={handlePurchaseChange}
        />
        <p className="text-xs text-right text-monochrome-20 min-w-[20%]">{formatCurrency(amount)} USD</p>
      </div>
      {/* <div className="my-3 w-full flex justify-between gap-5 items-center rounded-md border border-monochrome-40 p-2 overflow-hidden">
        <p className="text-monochrome-20">Estimated Profit (Yearly):</p>
        <h4>{formatCurrency(profit)}</h4>
      </div> */}
      <div className="w-full flex justify-end">
        <div className="flex gap-4 items-center">
          <Button onClick={handleClose} classNames="btn !bg-monochrome-60 !text-white">
            Cancel
          </Button>
          <Button
            onClick={handleNextPage}
            classNames={`btn btn-primary w-max ${purchase <= 0 ? "opacity-50 cursor-not-allowed" : ""}`}
            disabled={purchase <= 0}
          >
            Next &gt;
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InvestPageOne;
