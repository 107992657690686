import { useEffect, useState } from "react";
import { IUser } from "@/types";
import { IcArrowNext, IcArrowUp, IcJoinPenomoPreSale, IcPenomoWhitelistJoined } from "@/assets";
import Button from "./UI/Button";
import JoinPenomoPreSaleModal from "./Page/Dashboard/Dialogs/ModalJoinPenomoPreSale";
import PenomoPreSaleCounter from "./Page/Dashboard/PenomoPreSaleCounter";

// Define a list of restricted countries by their short codes
const restrictedCountries = ["IR", "KP", "SY", "US", "UM","IRN","PRK","SYR","USA",
  // "IN","IND"
];

interface PenomoPreSaleProps {
  isVerified: boolean;
  userData: IUser;
}

const PenomoPreSale = ({ isVerified, userData }: PenomoPreSaleProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isWhitelisted, setIsWhitelisted] = useState<boolean>(false);
  const [timerSecound, setTimerSecound] = useState<number>(0);
  const [isSaleEnded, setIsSaleEnded] = useState<boolean>(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (isRefresh: boolean = false) => {
    setIsWhitelisted(isRefresh);
    setShowModal(false);
  };

  useEffect(() => {
    if (userData && userData.walletDetails) {
      setIsWhitelisted(userData.walletDetails.isWalletWhitelisted);
      // Example timeout logic for testing
      // setTimeout(() => {
      //   setIsSaleEnded(true);
      //   setTimerSecound(dayjs("2024-07-23").diff(dayjs(), "second"));
      // }, 5000);
    }
  }, [userData]);

  // Check if the user's country of residence is restricted
  const isCountryRestricted = userData && restrictedCountries.includes(userData.countryOfResidence);

  if (!isVerified) {
    return (
      <div className="bg-monochrome-100 px-3 py-3 flex rounded-lg">
        <div className="flex grow items-center">
          <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-monochrome-20" />
          <div className="flex flex-col ml-4 text-monochrome-20">
            <h4 className="font-medium text-monochrome-20 text-sm">Join PNMO Community Presale</h4>
            <p className="mt-2 font-normal text-monochrome-20">
              {isCountryRestricted
                ? "PNMO presale is currently not available in your country."
                : "Please complete your KYC first."}
            </p>
          </div>
        </div>
        <Button disabled transparent={true}>
          <IcArrowNext />
        </Button>
      </div>
    );
  }

  if (timerSecound > 0) {
    return (
      <>
        <div className={`flex-col sm:flex-row gap-4 sm:gap-0 bg-monochrome-100 px-4 py-4 flex rounded-lg items-center`}>
          <div className="grid sm:grid-cols-3 gap-4 sm:gap-0 items-center text-monochrome-20 w-full">
            <div className="flex items-center gap-4">
              <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-green-500" />
              <h3 className="font-medium text-white text-sm">Presale starts in</h3>
            </div>
            <PenomoPreSaleCounter seconds={timerSecound} />
          </div>
        </div>
      </>
    );
  }

  if (isSaleEnded) {
    return (
      <>
        <div className={`flex flex-col sm:flex-row gap-4 sm:gap-0 bg-monochrome-100 px-4 py-4 rounded-lg items-center`}>
          <div className="flex items-center w-full">
            <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-green-500" />
            <div className="flex ml-4 text-monochrome-20 w-full items-center justify-between">
              <h3 className="font-medium text-white text-sm">Get PNMO</h3>
              <Button rounded primary>
                Purchase PNMO <IcArrowUp />
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (isWhitelisted) {
    return (
      <>
        <div className={`flex-col sm:flex-row gap-4 sm:gap-0 bg-monochrome-100 px-4 py-4 flex rounded-lg items-center`}>
          <div className="flex grow items-center">
            <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-green-500" />
            <div className="flex flex-col ml-4 text-monochrome-20">
              <h4 className="font-normal text-sm flex gap-x-2 items-center">
                You have been whitelisted for the PNMO community presale. <IcPenomoWhitelistJoined className="text-green-500" />
              </h4>
              <p className="font-medium text-white mt-2">We will notify you once it&apos;s open.</p>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (isCountryRestricted) {
    return (
      <div className={`flex-col sm:flex-row gap-4 sm:gap-0 bg-monochrome-100 px-4 py-4 flex rounded-lg items-center`}>
        <div className="flex grow items-center">
          <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-red-500" />
          <div className="flex flex-col ml-4 text-monochrome-20">
            <h3 className="font-medium text-sm text-white">
              PNMO presale is currently not available in your country.
            </h3>
          </div>
        </div>
        <Button disabled transparent={true}>
          <IcArrowNext />
        </Button>
      </div>
    );
  }

  return (
    <>
      <div className={`flex-row gap-4 sm:gap-0 bg-[#00cc9c] px-4 py-4 flex rounded-lg items-center`}>
        <div className="flex grow items-center">
          <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-green-500" />
          <div className="flex flex-col ml-4 text-monochrome-20">
            <h3 className="font-medium text-white">Join PNMO Community Presale</h3>
          </div>
        </div>
        <Button transparent={true} onClick={handleOpenModal}>
          <IcArrowNext />
        </Button>
      </div>
      {userData && showModal && <JoinPenomoPreSaleModal handleClose={handleCloseModal} user={userData} />}
    </>
  );
};

export default PenomoPreSale;
